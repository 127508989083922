@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
    font-family: 'Montserrat-Regular';
    src: url('../public/fonts/Montserrat-VariableFont_wght.ttf');
    font-weight: normal;
  } */

  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800&family=Quicksand:wght@300;400;600&display=swap');

  @layer base {
    html {
      font-family: 'Montserrat', sans-serif;;
    }
  }